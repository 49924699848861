<template>
    <div class="header_wrapper" v-if="volunteer">
        <div class="volunteer_content">

            <div class="volunteer_hd">
                <div class="volunteer_user_info">
                    <div class="volunteer_user_bd">
                        <div class="volunteer_avatar">
                            <img v-lazy="userAvatar" alt="">
                            <span class="tag"></span>
                        </div>
                        <div class="volunteer_nicknmae">
                            <span class="strong">{{volunteer.name}}</span>
                            <span class="tag">宠物筹筹款顾问</span>
                        </div>
                    </div>

                    <a v-if="volunteer.mobile"
                        :href="volunteerTel"
                        class="volunteer_user_tel"
                        @click="onButtonPhoneTop">
                        <i class="tel-icon"></i>{{volunteer.mobile}}
                    </a>
                </div>

                <div class="volunteer_qrcode" v-if="volunteer.wechat_qrcode">
                    <div
                        :style="{'backgroundImage':'url('+ volunteer.wechat_qrcode +')'}"
                        class="qrcode_thumb">
                        <img class="qrcode_img" v-lazy="volunteer.wechat_qrcode" alt="" />
                    </div>
                    <span class="desc">长按识别二维码</span>
                </div>
            </div>

            <HelpAmount
                v-if="volunteer.funds_amount > 0"
                :volunteerType="volunteer.employee_type"
                :totalAmount="volunteer.funds_amount"
            />
        </div>
    </div>
</template>

<script>
import saUtils from 'sensors-data-utils';
import HelpAmount from './HelpAmount';

export default {
    name:'BaseHeader',
    data () {
        return {}
    },
    components: {
        HelpAmount
    },
    props: {
        volunteer: {
            type: Object,
            required: true
        },
        userAvatar: {
            type: String,
            required: true
        },
        volunteerTel: {
            type: String,
            required: true
        },
    },
    methods: {
        onButtonPhoneTop() {
            saUtils.click('Button_Phone_Top')
        }
    }
}
</script>

<style lang='less' scoped>
.header_wrapper {
    background: url('../img/head_bg.png') no-repeat top center;
    padding: 63px 15px 28px;
    background-size: 100% auto;
}

.volunteer_content {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 5px 15px 0 rgba(131,140,129,0.12);
}

.volunteer_hd {
    padding: 24px 16px 20px;
    display: flex;
    justify-content: space-between;
}

.volunteer_user_bd {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
}

.volunteer_avatar {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    border-radius: 30px;
    margin-right: 15px;
    overflow: hidden;
    position: relative;
    background: #F5F5F5;
    img {
        width: 100%;
    }

    .tag {
        display: block;
        width: 100%;
        height: 100%;
        background: url('../img/tag.png') no-repeat center;
        background-size: cover;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
    }
}

.volunteer_nicknmae {

    span {
        display: block;
        color: #999;
        font-size: 13px;
    }

    .strong {
        text-align: left;
        font-size: 21px;
        color: #333;
        font-weight: 500;
        margin-bottom: 5px;
        &.active {
            max-width: 120px;
        }
    }

    .employee_id {
        margin-bottom: 5px;
    }

    .employee_id,
    .tag {
        line-height: 1;
    }
}

.volunteer_user_tel {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: #F4F5F5;
    font-size: 18px;
    color: #0099FF;
    line-height: 1;
    font-weight: 500;
    padding: 6px 10px;
    width: 154px;
    box-sizing: border-box;

    .tel-icon {
        display: inline-block;
        width: 8px;
        min-width: 8px;
        height: 12px;
        background: url('../img/tel-icon.png') no-repeat center;
        background-size: 100% auto;
        margin-right: 8px;
    }
}

.volunteer_qrcode {
    flex-shrink: 1;
    .qrcode_thumb {
        width: 68px;
        height: 68px;
        display: block;
        margin: 0 auto 8px;
        position: relative;
        background-size: cover;
    }
    .qrcode_img {
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0;
    }
    .desc {
        font-size: 13px;
        color: #999;
        text-align: center;
    }
}

.volunteer_ft {
    background: #FFF9F0;
    padding: 17px 15px 17px  42px;
    font-size: 15px;
    color: #666;
    border-radius: 0 0 4px 4px;
    position: relative;

    .hand-icon {
        width: 20px;
        height: 16px;
        background: url('../img/hand-icon.png') no-repeat center;
        background-size: 100%;
        margin-right: 8px;
        position: absolute;
        left: 15px;
        top: 22px;
    }

    .strong {
        display: inline-block;
        color: #E89300;
        padding: 0 3px;
        font-weight: 500;
        font-size: 18px;
    }
}

@media screen and (max-width: 340px) {
   .volunteer_nicknmae {
       .strong {
           font-size: 18px;
       }
   }
   .volunteer_qrcode  {
       width: 82px;
       text-align: center;
   }
}
</style>
