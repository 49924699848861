export default {
    "code": 0,
    "msg": "",
    "error": "",
    "data": {
        "phone": '13040892323',
        "employee_id": "50564",
        "name": "陈斌",
        "id_photo": "https://thumb.qschou.com/files/qschou.com/volunteer_material/98660898b2693032a64f085b4749765495675cfah5.jpeg",
        "qr_code": "https://thumb.qschou.com/files/qschou.com/volunteer_material/52760587ae0f5a24e4125d75cdca8b76c87f1595h5.jpeg",
        "total_amount": "80904200",
        "list": [{
            "uuid": "0afba7ff-a922-431b-9caf-9d1017de0a35",
            "name": "莺飞草长的20岁女孩不幸患血癌，移植后急性肠排，费用告急！",
            "image": "https://thumb.qschou.com/files/qschou.com/project/love/1608928750605440936hHa3b59fc94dac37985fd665d3e39db8154.jpg@!love.png",
            "support_number": "3120",
            "raised_amount": "7452200",
            "target_amount": "30000000",
            "state": "8192",
            "category_id": "3349",
            "created_at": "1608342522"
        },
        {
            "uuid": "5d72670a-8ddd-47ef-80cb-9c6cc519ffbf",
            "name": "远赴千里之外的安徽治疗，只为给孩子做移植手术，求助好心人帮助",
            "image": "https://thumb.qschou.com/files/qschou.com/project/love/1610605628050209035AIwfdb94b20f9bb1ec5039ea7366dcc0168.png@!love.png",
            "support_number": "1278",
            "raised_amount": "5726600",
            "target_amount": "30000000",
            "state": "512",
            "category_id": "3349",
            "created_at": "1610604092"
        },
        {
            "uuid": "ecd9039c-dce2-4592-9e80-14b09c121d5b",
            "name": "慈父不幸病重，恳求帮助，都在等他回家",
            "image": "https://thumb.qschou.com/files/qschou.com/project/love/1606560344911015592EDLe683dc8358c2a09e58b0b64fd27f4929.jpg@!love.png",
            "support_number": "977",
            "raised_amount": "5615700",
            "target_amount": "30000000",
            "state": "512",
            "category_id": "3349",
            "created_at": "1606361488"
        }],
        "honor_list": null,
    },
    "next": ""
}
