<template>
    <div
        v-if="volunteer"
        class="volunteer_wrapper"
        :class="{active: projectList && projectList.length >= 2}">

        <BaseHeader
            :volunteer="volunteer"
            :volunteerTel="volunteerTel"
            :userAvatar="userAvatar"
        />

        <ProjectList
            v-if="projectList && projectList.length"
            :list="projectList"
        />

        <div class="footer_wrapper" v-if="volunteer.mobile">
            <a :href="volunteerTel" class="tel_btn"
                @click="onButtonFooterTel">电话联系筹款顾问
            </a>
        </div>
    </div>
</template>

<script>
const defaultLogo = require('./img/default-logo.png');

import { shareCtrl, tracking } from '@/common/js/mmcModule';
import { getVolunteerInfo } from "./api";
import ProjectList from './components/ProjectList.vue';
import BaseHeader from './components/BaseHeader.vue';
import saUtils from 'sensors-data-utils';

const pagekey = 'H5_JSB_Volunteer';
export default {
    name: "VolunteerDetail",
    data() {
        return {
            volunteer: null,
            projectList: null,
            defaultLogo,
        }
    },
    components: {
        BaseHeader,
        ProjectList,
    },
    computed: {
        userAvatar() {
            return this.volunteer.photo || this.defaultLogo;
        },
        volunteerTel() {
            const mobile = this.volunteer.mobile;
            return `tel:${mobile}`;
        },
    },
    created() {
        showLoading('加载中...')
    },
    mounted() {
        const { mobile } = this.$route.query;
        getVolunteerInfo(mobile)
        .then(res => {
            this.isLoad = true;
            hideLoading();

            if (res.code == 0) {
                const data = res.data;
                this.volunteer = data;
                this.projectList = data.projects;
            }
            this.shareInit();
        })
        .catch(err => {
            hideLoading();
        })

        tracking.init();

        saUtils.init(pagekey);
        saUtils.pv();
    },

    methods: {
        shareInit() {
            const { mobile } = this.$route.query;
            const volunteer = this.volunteer;

            let title = `宠物筹-筹款顾问${volunteer.name}`;
            let titleMoment = `宠物筹-筹款顾问${volunteer.name}`;

            shareCtrl.init({
                url: `${window.BasePath}fund/volunteer/detail?mobile=${mobile}`,
                title: title,
                desc: '1对1免费服务，帮您更快筹款～',
                titleMoment: titleMoment,
                imgUrl: this.userAvatar,
                shareRecord: {
                    scene: pagekey,
                },
                success:()=>{}
            });
        },
        onButtonFooterTel() {
            saUtils.click('Button_Phone_Bottom')
        }
    }
};
</script>

<style lang="less" scoped>
.volunteer_wrapper {
    background: #F4F5F4;
    min-height: 100vh;
    &.active {
        padding-bottom: 110px;
    }
}

.footer_wrapper {
    width: 100%;
    background-image: #fff;
    box-shadow: 0 -2px 12px 0 rgba(0,0,0,0.10);
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 99;
    padding: 12px 15px;

    .tel_btn {
        display: block;
        width: 100%;
        font-weight: 500;
        border-radius: 4px;
        line-height: 1;
        font-size: 18px;
        color: #FFFFFF;
        text-align: center;
        background-image: linear-gradient(90deg, #40B9FF 0%, #009BFF 100%);
        padding: 15px 0;
        user-select: none;
    }
}
</style>
