<template>
    <div class="volunteer_amount">
        <i class="hand-icon"></i>累计帮助患者筹得<span class="strong"> {{totalAmount | formatAmount}} </span>元
    </div>
</template>

<script>
import utils from '@/common/js/utils'
export default {
    name: 'HelpAmount',
    data () {
        return {}
    },
    props: {
        totalAmount: {
            type: Number
        }
    },
    filters: {
        formatAmount(value) {
            return utils.formatAmount(value/100);
        }
    },
};
</script>

<style lang="less" scoped>

.volunteer_amount {
    background: #EDF8FF;
    font-size: 15px;
    color: #666;
    border-radius: 0 0 4px 4px;
    position: relative;
    text-align: left;
    padding: 17px 15px 17px  42px;

    .hand-icon {
        width: 20px;
        height: 16px;
        background: url('../img/hand-icon.png') no-repeat center;
        background-size: 100%;
        margin-right: 8px;
        position: absolute;
        left: 15px;
        top: 22px;
    }

    .strong {
        display: inline-block;
        color: #0099FF;
        padding: 0 3px;
        font-weight: 500;
        font-size: 18px;
    }
}
</style>
