import { mmcAjax } from '@/common/js/mmcModule';
import volunteer from './mock/volunteer.js';
const isMock = false;

export function getVolunteerInfo(moblie) {
    if (isMock) {
        return Promise.resolve(volunteer);
    };
    const url = `${window.APIBasePath}mmc/project/counselor/info/${moblie}`;
    return mmcAjax({
        type: 'GET',
        url,
    });
}
