
<template>
    <div class="project_cotent">
        <div class="project_label">帮助过的部分患者</div>
        <div class="project_list" v-if="list">
            <div class="item_group"
                v-for="(item, index) in list"
                :key="index"
                @click="goProjectDetail()">
                <div class="product_img">
                    <span
                        v-if="item.project_thumb"
                        :style="{'backgroundImage':'url('+ item.project_thumb +'?x-image-process=style/thumb.png)'}"
                        class="project_thumb">
                    </span>
                </div>
                <div class="project_info">
                    <h2 class="project_title">{{item.title}}</h2>
                    <div class="project_funding">
                        <div class="project_desc">已筹 <span class="strong">{{item.accumulative_num/100}}</span> 元</div>
                        <div class="project_desc">获帮助 <span class="strong">{{item.helped_num}}</span> 次</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import saUtils from 'sensors-data-utils';
export default {
    name:'ProjectList',
    data () {
       return {}
    },

    props: {
        list: {
            type: Array,
            required: true
        },
    },

    methods: {
        goProjectDetail() {
            saUtils.click('Click_Project_Detail');
        }
    }
}
</script>

<style lang='less' scoped>
.project_cotent {
    padding: 0 15px;
    .project_label {
        font-size: 18px;
        color: #333;
        line-height: 1;
        margin-bottom: 16px;
        font-weight: 500;
        text-align: left;
    }

    .product_img {
        margin-right: 12px;
        min-width: 60px;
        .project_thumb {
            display: block;
            width: 60px;
            height: 60px;
            border-radius: 2px;
            overflow: hidden;
            flex-shrink: 0;
            background-size: cover;
        }
    }

    .item_group {
        display: flex;
        justify-content: space-between;
        background: #FFFFFF;
        border-radius: 4px;
        padding: 12px;
        margin-bottom: 7px;
    }

    .item_group:last-child {
        margin-bottom: 0;
    }

    .project_info {
        overflow:hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: auto;
    }

    .project_title {
        font-size: 18px;
        color: #333;
        font-weight: 600;
        margin: 0 0 17px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;
    }

    .project_funding {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .project_desc {
            font-size: 12px;
            color: #666;
            line-height: 1
        }
        .strong {
            color: #0099FF;
            padding: 0 3px;
            font-weight: 500;
        }
    }
}
</style>
